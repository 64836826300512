import React, { useCallback } from "react";

import { BuSegmentItem, TaxonomyItem } from "shared/models/Taxonomy";
import styles from "./CreateCategorySidenav.module.scss";
import { Drawer, message } from "antd";
import { TaxonomyCascader } from "containers/TaxonomyCascader/TaxonomyCascader";
import { useEffect, useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { externalTaxonomyService } from "shared/services/ExternalTaxonomyAssociationService";
import { associationService } from "shared/services/AssociationService";

interface CreateCategorySidenavProps {
  isOpen: boolean;
  selectedCategory?: {
    parent: TaxonomyItem;
    item?: BuSegmentItem;
  };
  onClose: () => void;
}

export const ExternalTaxonomySideNav = ({
  isOpen,
  selectedCategory,
  onClose,
}: CreateCategorySidenavProps) => {
  const [externalTaxonomies, setExternalTaxonomies] = useState<number[]>([]);

  const findAssociations = useCallback(() => {
    setExternalTaxonomies([]);
    if (selectedCategory?.item?.id) {
      trackPromise(
        externalTaxonomyService.findExternalTaxonomyAssociatedIds(
          selectedCategory?.item?.id
        )
      )
        .then((associations) => setExternalTaxonomies([...associations.data]))
        .catch(() => message.error("Erro ao buscar associações"));
    }
  }, [selectedCategory?.item?.id]);

  // Find associated external taxonomies
  useEffect(() => {
    findAssociations();
  }, [findAssociations]);

  const onChangeCascader = (taxonomyIds: number[]) => {
    if (selectedCategory?.item?.id) {
      const isDeletion = taxonomyIds.length < externalTaxonomies.length;
      isDeletion ? handleDeletion(taxonomyIds) : handleAssociation(taxonomyIds);
    }
  };

  const handleAssociation = (taxonomyIds: number[]) => {
    taxonomyIds.forEach(async (taxonomyId) => {
      try {
        const newAssociation = await trackPromise(
          externalTaxonomyService.associate({
            taxonomyIdExternal: taxonomyId,
            taxonomyIdInternal: selectedCategory?.item?.id!,
          })
        );

        setExternalTaxonomies([
          ...externalTaxonomies,
          newAssociation.data.taxonomyIdExternal,
        ]);

        message.success("Taxonomias associadas com sucesso");
      } catch {
        message.error("Erro ao buscar associações");
      }
    });
  };

  const handleDeletion = (taxonomyIds: number[]) => {
    const deletedTaxonomy = externalTaxonomies.filter(
      (et) => !taxonomyIds.includes(et)
    )[0];

    if (deletedTaxonomy) {
      externalTaxonomyService
        .delete({
          taxonomyIdExternal: deletedTaxonomy,
          taxonomyIdInternal: selectedCategory?.item?.id!,
        })
        .then(() => {
          message.success("Relação removida com sucesso");
          findAssociations();
        })
        .catch(() => message.error("Erro ao remover associação"));
    }
  };

  return (
    <Drawer
      className={styles.drawer}
      visible={!!isOpen}
      onClose={onClose}
      closable={false}
      width={580}
      zIndex={10}
      destroyOnClose
    >
      <div className={styles.heading}>
        <h1>Taxonomias externas</h1>
      </div>
      <div className={styles.wrapper}>
        <div style={{ marginTop: "2%" }}>
          <TaxonomyCascader
            id={`external-taxonomy-cascader`}
            onChange={onChangeCascader}
            taxonomyIds={externalTaxonomies}
          />
        </div>
      </div>
    </Drawer>
  );
};
