import React from "react";
import styles from "./CreateCategorySidenav.module.scss";
import * as Yup from "yup";

import { useFormik } from "formik";

import { Drawer, Button, message } from "antd";
import { trackPromise } from "react-promise-tracker";
import { Input } from "components/Input";
import { MultiplePasteInput } from "components/MultiplePasteInput";
import { taxonomyService } from "shared/services/TaxonomyService";
import { TaxonomyItem, BuSegmentItem } from "shared/models/Taxonomy";
import { getErrorMessage } from "shared/utils/ResponseUtils";
import { YehSwitch } from "components/YehSwitch/YehSwitch";
import { TaxonomyCascader } from "containers/TaxonomyCascader/TaxonomyCascader";

interface CreateCategorySidenavProps {
  isOpen: boolean;
  selectedCategory?: {
    parent: TaxonomyItem;
    item?: BuSegmentItem;
  };
  onSubmit: () => void;
  onClose: () => void;
}

const validationSchema = Yup.object({
  categories: Yup.array()
    .min(1, "Deve preencher o nome da categoria.")
    .required("Campo obrigatório"),
  deleted: Yup.bool().required(),
});

interface CategoryToSave {
  categories: string[];
  deleted: boolean;
}

export const CreateCategorySidenav = ({
  isOpen,
  selectedCategory,
  onClose,
  onSubmit,
}: CreateCategorySidenavProps) => {
  const {
    handleSubmit,
    values,
    errors,
    resetForm,
    setFieldTouched,
    setFieldValue,
    touched,
  } = useFormik({
    initialValues: {
      categories: [],
      deleted: false,
      taxonomyIds: [],
    },
    validationSchema,
    onSubmit: () => {
      saveOrUpdateCategory(values);
    },
  });

  const handleClose = () => {
    resetForm();
    onClose();
  };

  const saveOrUpdateCategory = (values: CategoryToSave) => {
    if (selectedCategory && selectedCategory.item && selectedCategory.item.id) {
      editCategory(values);
    } else {
      saveCategory(values);
    }
  };

  const saveCategory = (values: CategoryToSave) => {
    if (!selectedCategory) {
      message.error(
        "Não será possível salvar pois nenhum item foi selecionado."
      );
      return;
    }
    trackPromise(
      taxonomyService.createTaxonomy({
        descriptions: values.categories,
        parentId: selectedCategory.parent.id,
        taxonomyTypeId: selectedCategory.parent.typeId,
      })
    )
      .then((result) => {
        message.success("Categoria(s) criada(s) com sucesso.");
        onSubmit();
      })
      .catch((error) => getErrorMessage("Erro ao salvar categoria."));
  };

  const editCategory = (values: CategoryToSave) => {
    const item = selectedCategory?.item;
    if (item) {
      trackPromise(
        taxonomyService.updateTaxonomy(item.id, {
          description: values.categories[0],
          parentId: item.segmentId,
          deleted: values.deleted,
        })
      )
        .then(() => {
          message.success("Taxonomia atualizada com sucesso.");
          onSubmit();
        })
        .catch(() => getErrorMessage("Erro ao atualizar taxonomia."));
    }
  };

  return (
    <Drawer
      className={styles.drawer}
      visible={!!isOpen}
      onClose={handleClose}
      closable={false}
      width={380}
      zIndex={10}
      destroyOnClose
    >
      <div className={styles.heading}>
        <h1>Nova categoria</h1>
      </div>
      <div className={styles.wrapper}>
        <div>
          <Input
            disabled={true}
            label="Categoria pai"
            value={selectedCategory && selectedCategory.parent.title}
          />
          <MultiplePasteInput
            defaultValue={
              selectedCategory &&
              selectedCategory.item &&
              selectedCategory.item.title
            }
            error={
              touched.categories && errors.categories
                ? (errors.categories as string)
                : null
            }
            label="Nome da categoria"
            name="categories"
            onBlur={() => setFieldTouched("categories")}
            onChange={(values: string[]) => {
              setFieldValue("categories", values);
            }}
            placeholder="Nome da categoria"
            tabIndex={1}
          />
          <YehSwitch
            checkedChildren="Ativo"
            unCheckedChildren="Inativo"
            checked={!values.deleted}
            onChange={(value) => setFieldValue("deleted", !value)}
            style={{ marginTop: "10px" }}
          />
        </div>
      </div>

      <div className={styles.buttons}>
        <Button type="link" onClick={handleClose}>
          Cancelar
        </Button>
        <Button type="primary" onClick={() => handleSubmit()} disabled={false}>
          {!!selectedCategory && !!selectedCategory.item ? "Salvar" : "Criar"}
        </Button>
      </div>
    </Drawer>
  );
};
