import { map as _map } from "lodash";
import { Taxonomy, TaxonomyItem, BUSegment } from "shared/models/Taxonomy";

export function generateBaseItem(levelAmount: number): TaxonomyItem[] {
  const dimensionedArray = Array(levelAmount);
  return _map(dimensionedArray, (item, index) => ({
    order: index + 1,
    typeId: 0,
    segmentId: 0,
    id: 0,
    title: `Nível ${index + 1} (Não Selecionado)`,
    color: "var(--color-dark-gray)",
    content: null,
    currentPage: 0,
    hasMore: false,
  }));
}

function fetchHeaderColorByCategory(bu: string) {
  switch (bu.toLowerCase()) {
    case "alimentar":
      return { color: "var(--color-green)", icon: "basket" };
    case "integrafarma":
      return { color: "var(--color-warning)", icon: "crossShield" };
    case "construção":
      return { color: "var(--color-dark-blue)", icon: "hammer" };
    default:
      return { color: "var(--color-dark-gray)" };
  }
}

export function generateBuSegmentItem(list: BUSegment[]) {
  return _map(list, (item) => ({
    id: item.branch[1].id,
    typeId: item.branch[1].type,
    order: 0,
    title: "BU / Segmento",
    segmentId: item.branch[0].id,
    subtitle: `${item.branch[0].label} / ${item.branch[1].label}`,
    quantity: item.totalChildren,
    bu: item.branch[0],
    segment: item.branch[1],
    deleted: false,
    ...fetchHeaderColorByCategory(item.branch[0].label),
  }));
}

export function generateCategoryItem(
  categoryList: Taxonomy[],
  segmentId: number,
  order: number,
  hasMore: boolean
) {
  return _map(categoryList, (item) => ({
    segmentId,
    order,
    hasMore,
    id: item.leafId,
    title: item.description,
    color: "var(--color-dark-gray)",
  }));
}

export function adaptCategoryList(
  categoryList: Taxonomy[],
  segmentId: number,
  order: number
) {
  return _map(categoryList, (item) => ({
    segmentId,
    order,
    id: item.leafId,
    title: item.description,
    color: "var(--color-dark-gray)",
    quantity: item.totalChildren,
    typeId: item.typeId,
    deleted: item.deleted,
  }));
}
